<!--
 * @Author: your name
 * @Date: 2021-11-30 10:40:33
 * @LastEditTime: 2022-01-05 13:49:43
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/_details/index.vue
-->
<template>
  <div>
    <TabHeader title="预报详情"></TabHeader>
    <div class="fd-outer">
      <a-row>
        <a-col :span="8">
          <span class="fd-s1">预报单号：{{ form.forecast_no }}</span>
        </a-col>
        <a-col :span="8">
          <span class="fd-s2">
            <span class="s1">计划类型：</span>
            <span class="s2" style="color: #ff0000" v-if="form.type == 2"
              >未建计划</span
            >
            <span class="s2" v-if="form.type == 1">计划</span>
          </span>
        </a-col>
      </a-row>
      <div class="fd-line"></div>
      <div class="fd-inner">
        <div class="fd-inner-title">预报信息</div>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">预报时间：</span
              ><span class="s2">{{ form.created_at | timeStr }}</span>
            </span>
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">货件数量：</span
              ><span class="s2">{{ form.box_count }}件</span></span
            >
            <a-button
              type="link"
              @click="eidtCount(form)"
              v-if="form.type == 2 && status == 1"
              >修改
            </a-button>
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">已发货数量：</span
              ><span class="s2">{{ form.use_box_count }}件</span></span
            >
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">运单数：</span
              ><span class="s2">{{ form.waybill_count }}</span></span
            >
          </a-col>
          <a-col :span="8" v-if="form.receive_type != 2">
            <span class="fd-s2"
              ><span class="s1">货好时间：</span
              ><span class="s2">{{ form.good_time | timeStr }}</span>
              <span>
                <a-button
                  type="link"
                  style="height: 10px"
                  v-if="form.good_time > 0"
                  @click="
                    (modalObj.visible = true),
                      (modalObj.index = 2),
                      (modalObj.title = '修改货好时间')
                  "
                  >修改
                </a-button>
              </span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">重量：</span
              ><span class="s2">{{ form.weight }}kg</span></span
            >
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">体积：</span
              ><span class="s2">{{ form.volume }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">收货地址：</span
              ><span class="s2">{{ form.address }}</span>
              <!-- <chooseAdress  v-model="form.addressId"></chooseAdress>   -->
            </span>
            <chooseAdress
              v-model="form.addressId"
              @editAddress="editAddress"
              ref="cad"
              :edit="true"
              style="display: inline-block; margin-left: 15px"
              v-if="status == 1"
              >修改>
            </chooseAdress>
          </a-col>
        </a-row>
      </div>
      <div class="fd-line" v-if="form.receive_type == 1"></div>
      <div class="fd-inner" v-if="form.receive_type == 1">
        <div class="fd-inner-title">司机信息</div>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2" v-if="form.other_driver.name"
              ><span class="s1">姓名：</span
              ><span class="s2">{{ form.other_driver.name }}</span></span
            >
            <span class="fd-s2" v-else-if="form.driver.name"
              ><span class="s1">姓名：</span
              ><span class="s2">{{ form.driver.name }}</span></span
            >
            <span class="fd-s2" v-else
              ><span class="s1">姓名：</span
              ><span class="s2">司机分配中</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2" v-if="form.other_driver.phone"
              ><span class="s1">手机号：</span
              ><span class="s2">{{ form.other_driver.phone }}</span></span
            >
            <span class="fd-s2" v-else-if="form.driver.phone"
              ><span class="s1">手机号：</span
              ><span class="s2">{{ form.driver.phone }}</span></span
            >
            <span class="fd-s2" v-else
              ><span class="s1">手机号：</span><span class="s2">-</span></span
            >
          </a-col>
        </a-row>
      </div>
      <div class="fd-line"></div>
      <div class="fd-inner" v-if="form.type == 1">
        <div class="fd-inner-title">运单</div>
        <a-row
          style="margin-top: 20px"
          v-for="(item, index) in form.waybills"
          :key="index"
        >
          <a-col :span="4">
            <span class="fd-s2"
              ><span class="s1">运单编号：</span
              ><span class="s2">{{ item.waybill_no }}</span></span
            >
          </a-col>
          <a-col :span="4">
            <span class="fd-s2"
              ><span class="s1">件数：</span
              ><span class="s2">{{ item.cargoes_num }}</span></span
            >
            <a-button type="link" @click="eidtCount(item)" v-if="status == 1"
              >修改
            </a-button>
          </a-col>
          <a-col :span="4">
            <span class="fd-s2"
              ><span class="s1">国家或地区：</span
              ><span class="s2">{{ item.country_name }}</span></span
            >
            <a-button type="link" @click="eidtChannel(item)" v-if="status == 1"
              >修改
            </a-button>
          </a-col>
          <a-col :span="4">
            <span class="fd-s2"
              ><span class="s1">渠道：</span
              ><span class="s2">{{ item.channel_name }}</span></span
            >
            <a-button type="link" @click="eidtChannel(item)" v-if="status == 1"
              >修改
            </a-button>
          </a-col>
          <a-col :span="4">
            <span class="fd-s2"
              ><span class="s1">品名:</span
              ><span class="s2">{{ item.new_product_name }}</span></span
            >
            <a-button
              type="link"
              @click="eidtProductName(item)"
              v-if="status == 1"
              >修改
            </a-button>
          </a-col>
          <a-col :span="4">
            <span class="fd-s2"
              ><span class="s1">材质:</span
              ><span class="s2">{{ forMatCz(item) }}</span></span
            >
            <a-button
              type="link"
              @click="eidtProductName(item)"
              v-if="status == 1"
              >修改
            </a-button>
          </a-col>
          <a-col :span="4">
            <span class="fd-s2"
              ><span class="s1">运单备注:</span
              ><span class="s2"> {{ item.remark }}</span></span
            >
            <a-button
              type="link"
              @click="eidtRemarkHandle(item)"
              v-if="status == 1"
              >修改</a-button
            >
          </a-col>
        </a-row>
      </div>
      <div class="fd-line" v-if="form.type == 1"></div>
      <div class="fd-inner">
        <div class="fd-inner-title">照片</div>
        <a-row style="margin-top: 20px" v-if="show == true">
          <a-col :span="6">
            <img :src="form.photos" class="fd-img1" />
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px" v-if="show == false">
          <a-col :span="6">
            <span>无照片</span>
          </a-col>
        </a-row>
      </div>
      <div style="margin-top: 20px">
        <a-button type="primary" @click="goBack"> 返回 </a-button>
        <a-button
          type="primary"
          ghost
          style="margin-left: 15px"
          v-if="form.type === 2 && form.use_box_count < form.box_count"
          @click="handlerDelivery(form)"
        >
          发货
        </a-button>
        <!-- <a-popconfirm ok-text="确定" cancel-text="取消" @confirm="handlerCancel(form)">
          <template slot="title">
            <span>确定取消预报嘛？</span>
          </template>
          <a-button type="primary" ghost style="margin-left: 15px">
            取消预报
          </a-button>
        </a-popconfirm> -->
      </div>
    </div>
    <a-drawer
      :title="modalObj.title"
      :visible="modalObj.visible"
      :width="modalObj.width"
      @close="handleCancel"
      :maskClosable="false"
    >
      <ship
        ref="shipRef"
        :id="rowId"
        :limit="limit"
        v-if="modalObj.visible && modalObj.index == 0"
        @success="handlerDe"
        @fail="handlerFail"
      ></ship>
      <editCount
        v-if="modalObj.visible && modalObj.index == 1"
        :id="rowId"
        :infoData="infoData"
        ref="editForm"
      >
      </editCount>
      <a-form-model
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        ref="forms1"
        :model="forms1"
      >
        <a-form-model-item
          label="货好时间"
          v-if="modalObj.visible && modalObj.index == 2"
        >
          <a-date-picker show-time @change="dateChange1" v-model="mode1" />
        </a-form-model-item>
        <a-form-model-item
          label="国家或地区"
          v-if="modalObj.visible && modalObj.index == 3"
          placeholder="请选择国家或地区"
          prop="countryId"
          :rules="validate({ name: 'value', msg: '请选择国家或地区' })"
        >
          <a-select v-model="forms1.countryId">
            <a-select-option
              v-for="(item, index) in countryData"
              :key="index"
              :value="item.id"
              >{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="渠道"
          v-if="modalObj.visible && modalObj.index == 3"
          placeholder="请选择渠道"
          prop="channelId"
          :rules="validate({ name: 'value', msg: '请选择渠道' })"
        >
          <a-select
            v-model="forms1.channelId"
            show-search
            :filter-option="filterOption"
          >
            <a-select-option
              v-for="(item, index) in channelData"
              :key="index"
              :value="item.id"
              >{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="品名"
          v-if="modalObj.visible && modalObj.index == 4"
          placeholder="请输入品名"
          prop="newProductName"
        >
          <a-input v-model="forms1.newProductName"></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="材质"
          prop="newMaterialCates"
          v-if="modalObj.visible && modalObj.index == 4"
          :rules="validate({ name: 'value', msg: '请选择材质' })"
        >
          <a-checkbox-group
            v-model="forms1.newMaterialCates"
            :options="plainOptions"
          >
            <span slot="label" slot-scope="{ value }" style="color: red">{{
              value
            }}</span>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item
          label="订单备注"
          v-if="modalObj.visible && modalObj.index == 5"
        >
          <a-textarea
            v-model="forms1.remark"
            placeholder="请输入订单备注"
            :auto-size="{ minRows: 2, maxRows: 6 }"
          />
        </a-form-model-item>
      </a-form-model>
      <div class="drawerFooter">
        <a-button-group>
          <a-button @click="handleCancel"> 取消 </a-button>
          <a-button type="primary" @click="handleOk"> 确定 </a-button>
        </a-button-group>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
  forecastInfo,
  forecastCancel,
  goodTimeUpDate,
  updateBillRemark,
} from "@/api/Waybill";
import { countryList, channelCountry, materialList } from "@/api/comm";
import moment from "moment";
import ship from "../curd/ship.vue";
import { waybillEdit } from "@/api/Waybill";
import editCount from "../curd/editCount.vue";
// import imgUrl from "@/utils/imgUrl"
export default {
  name: "Waybill_Forecast_details",
  components: {
    ship,
    editCount,
  },
  watch: {
    "forms1.countryId": {
      handler(val) {
        this.editType == "eidtChannel" ? this.countryChange(val) : "";
      },
      deep: true,
    },
  },
  data() {
    return {
      editType: "",
      rowId: "",
      limit: 1,
      forms1: {
        countryId: "",
        channelId: "",
        remark: "",
      },
      channelData: [],
      confirmLoading: false,
      modalObj: {
        title: "",
        visible: false,
        width: "700px",
        index: "",
      },
      countryData: [],
      mode1: "",
      show: true,
      plainOptions: [],
      form: {
        addressId: "",
        forecast_no: "",
        type: "",
        created_at: "",
        box_count: "",
        use_box_count: "",
        waybill_count: "",
        good_time: "",
        weight: "",
        address: "",
        volume: "",
        photos: "",
        driver: {
          name: "",
          phone: "",
        },
        other_driver: {
          name: "",
          phone: "",
        },
        infoData: "",
        waybills: [],
      },
    };
  },
  created() {
    this.getDetails();
    this.status = this.$route.query.status;
    countryList().then((res) => {
      this.countryData = res.data.list;
    });
    materialList().then((res) => {
      for (let i of res.data) {
        if (["普货", "带电", "带磁", "其他"].indexOf(i.name) != -1) {
          this.plainOptions.push({
            label: i.name,
            value: i.id,
          });
        }
      }
    });
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    editAddress(val) {
      if (val == true) {
        this.$refs.cad
          .editAddress(Number(this.$route.query.id))
          .then(() => {
            this.$message.success("修改成功");
            this.getDetails();
          })
          .catch(() => {
            this.getDetails();
          });
      }
    },
    forMatCz(val) {
      let str = "";
      if (val.new_material_cates) {
        for (let i of val.new_material_cates) {
          str += i.name + ",";
        }
      } else {
        str = "暂无";
      }
      return str;
    },
    countryChange(val) {
      channelCountry({
        countryId: val,
      }).then((res) => {
        this.channelData = res.data;
      });
    },
    eidtChannel(item) {
      this.editType = "eidtChannel";
      this.forms1 = Object.assign(this.forms1, item);
      this.forms1.waybillId = this.forms1.id;
      this.forms1.countryId = item.country_id;
      this.modalObj.visible = true;
      this.modalObj.title = "修改国家或地区渠道";
      this.modalObj.index = 3;
    },
    eidtProductName(item) {
      this.editType = "eidtProductName";
      this.forms1 = Object.assign(this.forms1, item);
      this.forms1.waybillId = this.forms1.id;
      this.modalObj.visible = true;
      this.modalObj.title = "修改品名与材质";
      this.modalObj.index = 4;
    },
    eidtRemarkHandle(item) {
      this.forms1.remark = item.remark;
      this.forms1.waybillId = item.id;
      this.modalObj.visible = true;
      this.modalObj.title = "修改订单备注";
      this.modalObj.index = 5;
    },
    dateChange1(val) {
      console.log(moment(val).format("YYYY-MM-DD HH:mm:ss"));
    },
    getDetails() {
      let id = this.$route.query.id;
      if (id) {
        this.rowId = id;
        forecastInfo({
          forecastId: id * 1,
        }).then((res) => {
          this.form = {
            ...res.data,
          };
          if (this.form.photos != null) {
            this.show = true;
          } else {
            this.show = false;
          }
          const arr = this.form.waybills.filter(
            (ele) => ele.finish_status != 2
          );
          this.form.waybills = [...arr];
          if (this.form.photos) {
            if (!this.form.photos.includes("http")) {
              this.form.photos = process.env.VUE_APP_CDN + this.form.photos;
            }
          }
          // this.form.photos = process.env.VUE_APP_CDN + this.form.photos;
          this.mode1 = moment(this.form.good_time * 1000);
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    // 取消预报
    handlerCancel(row) {
      let obj = {
        forecastId: row.id,
      };
      forecastCancel(obj).then(() => {
        this.$message.success("取消成功");
        this.getDetails();
      });
    },
    // 发货
    handlerDelivery(row) {
      this.rowId = row.id;
      this.modalObj.title = "发货";
      this.limit = row.box_count - row.use_box_count;
      this.modalObj.visible = true;
      this.modalObj.index = 0;
    },
    // 关闭model
    handleCancel() {
      this.modalObj.visible = false;
    },
    // 提交
    handleOk() {
      if (this.modalObj.index == 1) {
        this.$refs.editForm.submit().then(() => {
          this.getDetails();
          this.modalObj.visible = false;
        });
      } else if (this.modalObj.index == 0) {
        this.confirmLoading = true;
        this.$refs.shipRef.onSubmit();
      } else if (this.modalObj.index == 2) {
        goodTimeUpDate({
          forecastId: Number(this.$route.query.id),
          goodTime: moment(this.mode1).format("YYYY-MM-DD HH:mm:ss"),
        }).then(() => {
          this.$message.success("修改成功");
          this.getDetails();
          this.modalObj.visible = false;
        });
      } else if (this.modalObj.index == 3 || this.modalObj.index == 4) {
        this.$refs.forms1.validate((valid) => {
          if (valid) {
            waybillEdit(this.forms1).then(() => {
              this.$message.success("修改成功");
              this.getDetails();
              this.modalObj.visible = false;
            });
          } else {
            this.$message.error("需填写完整信息");
          }
        });
      } else if (this.modalObj.index === 5) {
        updateBillRemark(this.forms1).then(() => {
          this.$message.success("修改成功");
          this.getDetails();
          this.modalObj.visible = false;
        });
      }
    },
    // 发货成功
    handlerDe() {
      this.confirmLoading = false;
      this.modalObj.visible = false;
    },
    // 发货失败
    handlerFail() {
      this.confirmLoading = false;
    },
    eidtCount(val) {
      this.infoData = val;
      this.modalObj.visible = true;
      this.modalObj.title = "修改";
      this.modalObj.index = 1;
    },
  },
  filters: {
    timeStr(val) {
      let str = "";
      if (val && val > 0) {
        let times = val * 1000;
        str = moment(times).format("YYYY-MM-DD HH:mm:ss");
      }
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
.fd-outer {
  padding: 20px 30px;
}

.fd-s1 {
  font-size: 20px;
  color: #fb4e0c;
}

.fd-s2 {
  .s1 {
    font-size: 14px;
    color: #5c5c5c;
  }

  .s2 {
    font-size: 14px;
    color: #9a9a9a;
  }
}

.fd-inner {
  margin-top: 20px;

  .fd-inner-title {
    font-size: 16px;
    font-weight: 600;
  }

  .fd-img1 {
    width: 180px;
    height: 167px;
  }
}
</style>
