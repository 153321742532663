<!--
 * @Author: your name
 * @Date: 2021-12-01 19:00:03
 * @LastEditTime: 2021-12-29 11:18:39
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/Forecast/curd/ship.vue
-->
<template>
    <a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <template v-for="(item,index) in form.tickets">
            <a-row :key="index">
                <a-form-model-item label="是否FBA" prop="type">
                    <a-row>
                        <a-col :span="7">
                            <a-radio-group v-model="item.type" @change="handlerFba(item)">
                                <a-radio :value="2">
                                    非FBA
                                </a-radio>
                                <a-radio :value="1">
                                    FBA
                                </a-radio>
                            </a-radio-group>
                        </a-col>
                        <a-col :span="4">
                            <a-button v-if="index === form.tickets.length - 1" type="primary" icon="plus" @click="addTickets(form.tickets)" >添加票据</a-button>
                            <a-button v-else type="primary" icon="minus" @click="reduceTickets(form.tickets, index)" />
                        </a-col>
                    </a-row>
                </a-form-model-item>
                <a-form-model-item label="目的地" :prop="`tickets.`+index+'.addressId'" :rules="validate({name: 'value', msg: '请选择目的地'})">
                     <chooseAdress v-model="item.addressId" :type="addressType" @change="handlerAdress(item)"></chooseAdress>
                </a-form-model-item>
                <a-form-model-item label="渠道类型" v-if="item.addressId">
                    <a-radio-group v-model="item.cate" @change="handlerCate(item)">
                        <a-radio :value="it.id" v-for="it in channelType" :key="it.id">
                            {{it.name}}
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="渠道" :prop="`tickets.`+index+'.channelId'" :rules="validate({name: 'value',msg: '请选择渠道'})" v-if="item.addressId">
                    <a-select v-model="item.channelId" style="width: 200px" allowClear placeholder="渠道">
                        <a-select-option :value="it.id" v-for="it in channelList" :key="it.id">
                            {{it.name}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="是否投保" prop="haveSafe">
                    <a-radio-group v-model="item.haveSafe">
                        <a-radio :value="0">
                            否
                        </a-radio>
                        <a-radio :value="1">
                            是
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="材质" :prop="`tickets.`+index+'.materialCates'" :rules="validate({name: 'value', type: 'array', msg: '材质不能为空'})">
                    <a-checkbox-group v-model="item.materialCates" :options="materials">
                    </a-checkbox-group>
                </a-form-model-item>
                <a-form-model-item label="备注" prop="remark">
                    <a-input v-model="item.remark" type="textarea" style="width: 300px" />
                </a-form-model-item>
                <a-form-model-item label="货件列表" :prop="`tickets.`+index+'.checklists'" :rules="validate({name: 'value', type: 'array', msg: '货件列表不能为空'})">
                    <a-row v-for="(it,x) in item.checklists" :key="x">
                        <a-row>
                            <a-col :span="9" style="margin-right: 5px">
                                <a-form-model-item :prop="`tickets[${index}].checklists[${x}].no`" :rules="validate({name: 'value', msg: '货物编号不能为空'})">
                                    <a-input v-model="it.no" allowClear placeholder="货物编号" style="width: 200px" />
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="2">
                                <a-button v-if="x === item.checklists.length - 1" type="primary" icon="plus" @click="addChecklists(item.checklists)">添加货件</a-button>
                                <a-button v-else type="primary" icon="minus" @click="reduceChecklists(item.checklists, x)" />
                            </a-col>
                        </a-row>
                        <a-row v-for="(iit,n) in it.products" :key="'p'+n">
                            <a-col :span="9" style="margin-right: 5px">
                                <a-form-model-item :prop="`tickets[${index}].checklists[${x}].products[${n}].productId`" :rules="validate({name: 'value', msg: '产品id不能为空'})">
                                    <!-- <a-input v-model="iit.productId" allowClear placeholder="产品id" /> -->
                                     <chooseProduct v-model="iit.productId"></chooseProduct>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="9" style="margin-right: 5px">
                                <a-form-model-item :prop="`tickets[${index}].checklists[${x}].products[${n}].productCount`" :rules="validate({name: 'value', msg: '产品数量不能为空'})">
                                    <a-input-number style="width: 100%" allowClear v-model="iit.productCount" placeholder="产品数量" />
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="4">
                                <a-button v-if="n === it.products.length - 1" type="primary" icon="plus" @click="add(it.products)" >添加产品</a-button>
                                <a-button v-else type="primary" icon="minus" @click="reduce(it.products, n)" />
                            </a-col>
                        </a-row>
                    </a-row>
                </a-form-model-item>
            </a-row>
        </template>
    </a-form-model>
</template>

<script>
import {
    channelList,
    channelType,
    materialList
} from "@/api/comm";
import { forecastDelivery } from "@/api/Waybill";
export default {
    props: {
        id: {
            type: [String, Number],
            default: "",
        },
        limit: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            addressList: [],
            channelList: [],
            channelType: [],
            products: [],
            materials: [],
            addressType: "fba",
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            form: {
                forecastId: "",
                tickets: [
                    {
                        type: 1,
                        cate: 1,
                        addressId: "",
                        channelId: "",
                        haveSafe: 0,
                        materialCates: [],
                        remark: "",
                        checklists: [
                            {
                                no: "",
                                products: [
                                    {
                                        productId: "",
                                        productCount: null,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        };
    },
    created() {
        this.form.forecastId = this.id;
        this.getChannelType();
        this.materialList();
    },
    methods: {
        // 改变fba
        handlerFba(row) {
            row.addressId = "";
            if(row.type === 1) {
                this.addressType = 'fba'
            } else {
                this.addressType = 1
            }
        },
        // 改变收获地址
        handlerAdress(row) {
            row.channelId = "";
            this.getChannel(row);
        },
         // 获取材质
        materialList() {
            materialList().then(res => {
                let list = res.data;
                const arr = [];
                list.forEach((ele) => {
                    let obj = {
                        label: ele.name,
                        value: ele.id,
                    };
                    arr.push(obj);
                });
                this.materials = arr;
            })
        },
        // 改变渠道类型
        handlerCate(row) {
            row.channelId = "";
            this.getChannel(row);
        },
        // 获取渠道类型
        getChannelType() {
            channelType().then((res) => {
                this.channelType = res.data;
            });
        },
        // 获取渠道
        getChannel(row) {
            let obj = {
                cate: row.cate,
                type: row.type,
                addressId: row.addressId,
            };
            channelList(obj).then((res) => {
                this.channelList = res.data.list;
            });
        },
        // 增加货物
        add(arr) {
            let obj = {
                productId: "",
                productCount: null,
            };
            arr.push(obj);
        },
        // 减少
        reduce(arr, n) {
            arr.splice(n, 1);
        },
        addChecklists(arr) {
            let obj = {
                no: "",
                products: [
                    {
                        productId: "",
                        productCount: null,
                    },
                ],
            };
            arr.push(obj);
        },
        reduceChecklists(arr, n) {
            arr.splice(n, 1);
        },
        addTickets(arr) {
            let obj = {
                type: 2,
                cate: 1,
                addressId: "",
                channelId: "",
                haveSafe: 0,
                materialCates: [],
                remark: "",
                checklists: [
                    {
                        no: "",
                        products: [
                            {
                                productId: "",
                                productCount: null,
                            },
                        ],
                    },
                ],
            };
            arr.push(obj);
        },
        reduceTickets(arr, n) {
            arr.splice(n, 1);
        },
        // 提交的方法
        onSubmit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    forecastDelivery(this.form)
                        .then(() => {
                            this.$message.success("发货成功");
                            this.$emit("success");
                        })
                        .catch(() => {
                            this.$emit("fail");
                        });
                } else {
                    this.$emit("fail");
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
</style>
